import { stepsListIndexes, ZOOM_HIERARCHY_MIN_LEVEL } from '@/shared/constants'

export default function (this: any, settlement) {
    // this.$dispatch('setStepActiveId', stepsListIndexes.districtInfoStepIndex)
    console.log('settlement chosen', settlement)

    if (!settlement.geometry) return

    this.$commit('setPartialState', {
        focusedCityAreaNumber: settlement.id,
    })
    this.$commit('setPartialState', {
        focusedCityAreaName: settlement.label,
    })

    this.$map.jumpTo({
        center: settlement.geometry,
        zoom: ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.settlementsStepIndex],
    })
}
