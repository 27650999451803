<template>
    <!--    <f7-list :class="$style.accordion" accordion-list inset>-->
    <!--        <f7-list-item accordion-item accordion-item-opened>-->
    <!--            <template #title> Населенные пункты </template>-->
    <!--            <f7-accordion-content>-->
    <!--                <TreeView-->
    <!--                    v-bind="{-->
    <!--                        handleNodeClick: handleSettlementNodeClick,-->
    <!--                        dataTreeList: settlementsTree,-->
    <!--                        isLoading,-->
    <!--                    }"-->
    <!--                />-->
    <!--            </f7-accordion-content>-->
    <!--        </f7-list-item>-->
    <!--    </f7-list>-->
    <div>
        <LocationsList
            v-bind="{
                handleLocationClick: handleSettlementNodeClick,
                locations: settlementsTree,
                isLoading,
            }"
        />
    </div>
</template>
<script>
import TreeView from '../../../../../widgets/MenuPanel/ui/TreeView'
import LocationsList from '../../../../general/LocationsList/ui/LocationsList.vue'

import loadSource from '../../../../../entities/Map/general/model/loadSource'
import handleSettlementNodeClick from './methods/handleSettlementNodeClick'
import { mapState } from 'vuex'
import isAllowMenuChange from '../../../../../shared/lib/isAllowMenuChange'
import { appModuleIds } from '../../../../../shared/constants'

export default {
    name: 'DistrictsView',
    props: {},
    components: {
        // TreeView,
        LocationsList,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    mounted() {
        this.$dispatch('setDistrictsStats', this)
        this.setSettlementsList()
    },
    watch: {
        chosenDistrictId(newChosenDistrictId) {
            console.log('newChosenDistrictId chached', newChosenDistrictId)

            if (!newChosenDistrictId) return

            this.setSettlementsList()
        },
        async focusedDistrictNumber() {
            await this.setSettlementsList()
        },
        async focusedRegionNumber() {
            await this.setSettlementsList()
            await this.$dispatch('setDistrictsStats', this)
        },
        async chosenAppModule() {
            await this.setSettlementsList()
            await this.$dispatch('setDistrictsStats', this)
        },
    },
    methods: {
        loadSource,
        handleSettlementNodeClick,
        isAllowMenuChange,

        async setSettlementsList() {
            if (this.$state.chosenAppModule?.id !== appModuleIds.gasification)
                return

            this.isLoading = true

            await this.$dispatch('setSettlementsStats', this)
            await this.$commit('setSettlementsList')

            this.isLoading = false
        },
    },
    computed: {
        ...mapState({
            settlementsTree: state => state.view.treeLists.settlements,
            chosenDistrictId: state =>
                state.view.districtsView.chosenDistrict.id,
            focusedRegionName: state => state.focusedRegionName,
            focusedRegionNumber: state => state.focusedRegionNumber,
            focusedDistrictNumber: state => state.focusedDistrictNumber,
            focusedDistrictName: state => state.focusedDistrictName,
            settlementsProposalsStats: state =>
                state.view.statsProposals.settlements,
            chosenAppModule: state => state.chosenAppModule,
        }),
        appModuleIds() {
            return appModuleIds
        },
    },
}
</script>

<style lang="stylus" module>
.blockText
    &:global(.block)
        margin-bottom 0
.accordion
    &:global(.list)
        margin-top var(--indent2)
.districtInfoBlockWrapper
    width 100%
    // max-height 720px
    display flex
    flex-direction column
.districtInfoBlock
    width 100%
    display flex
    flex-direction column
.header
    flex-shrink 0
    display flex
    align-items center
    padding var(--indent1)
.title
    width 100%
    padding-right var(--indent1)
    text-transform uppercase
    font-weight bold
.closeTrigger
    flex-shrink 0
.content
    width 100%
    display flex
    justify-content center

.collapse
    width 100%
    &:global(.el-collapse)
        --el-collapse-header-background-color transparent
        --el-collapse-content-background-color transparent
        border none
    :global(.el-collapse-item__header)
        align-items baseline
        padding 0 var(--indent1)
</style>
