<template>
    <div>
        <f7-list v-if="chosenAppModule?.id === appModuleIds.spg" inset>
            <f7-list-item> просмотр информации по району </f7-list-item>
        </f7-list>
        <SettlementsList
            v-if="chosenAppModule?.id === appModuleIds.gasification"
        />
        <DistrictInfoBlock
            v-if="
                chosenAppModule?.id === appModuleIds.gasification ||
                chosenAppModule?.id === appModuleIds.gasificationPlan
            "
        />
        <f7-list
            inset
            v-if="
                chosenAppModule?.id === appModuleIds.gasification ||
                chosenAppModule?.id === appModuleIds.gasificationPlan
            "
        >
            <f7-list-item
                link
                title="Информация по ГРС"
                v-if="this.$state.view.gdsMenu.state"
                @click="
                    this.$dispatch(
                        'setStepActiveId',
                        stepsListIndexes.gdsInfoStepIndex
                    )
                "
            ></f7-list-item>
        </f7-list>
    </div>
</template>
<script>
import DistrictInfoBlock from '../../features/gasification/DistrictInfoBlock'
import SettlementsList from '../../features/gasification/DistrictInfoBlock/components/SettlementsList'
import { mapState } from 'vuex'
import { appModuleIds, stepsListIndexes } from '../../shared/constants'

export default {
    name: 'DistrictInfoView',
    components: {
        DistrictInfoBlock,
        SettlementsList,
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            focusedRegionName: state => state.focusedRegionName,
            focusedDistrictName: state => state.focusedDistrictName,
            focusedCityAreaName: state => state.focusedCityAreaName,
            chosenAppModule: state => state.chosenAppModule,
        }),
        appModuleIds() {
            return appModuleIds
        },
        stepsListIndexes() {
            return stepsListIndexes
        },
    },
}
</script>

<style src="../DistrictsView/index.styl" lang="stylus" module></style>
